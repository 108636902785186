<i18n>
es:
  date: '2 de Abril 2022'
  invalid: 'La liga de esta invitación no es válida, favor de usar la liga correcta.'
  seeMore: 'Para ver los detalles del evento, favor de usar la liga que te enviaron los novios.'
  dear: 'Queridos'
  people: 'persona|personas'
  days: 'día|días'
  hours: 'hora|horas'
  minutes: 'minuto|minutos'
  religious: 'Misa'
  civil: 'Civil'
  party: 'Recepción'
  welcome: "Estamos muy felices y emocionados de invitarlos a formar parte de la celebración de nuestra unión. Ustedes han sido importantes en nuestro camino y no imaginamos celebrando este día sin ustedes. Los esperamos con los brazos abiertos."
  cantAttend: "Entendemos que puede ser complicado viajar en estos momentos. En caso de que no nos puedan acompañar presencialmente los invitaremos a la transmisión por zoom de la ceremonia civil donde al final podremos hacer un brindis virtual."
  qa:
    covid:
      q: "¿Cuáles son las medidas de prevención COVID?"
      bullets:
        - "Les pedimos el uso correcto del cubrebocas. Si los invitados lo desean pueden quitarse el cubrebocas al estar sentados en su mesa. Siéntase con libertad de pedirle a alguien mas que se coloque el cubrebocas. Favor de usar cubrebocas para desplazarse en el jardín cuando estén cerca de más personas."
        - "Si cerca de la fecha de la boda presentan síntomas, por favor tomen las medidas apropiadas y avísennos para poder invitarlos a la transmisón de la ceremonia civil. Queremos acompañarnos no solo hoy, sino durante mucho tiempo."
        - "Habrá gel antibacterial disponible en todas las mesas."
        - "Les pedimos usar su cubrebocas en la pista de baile."
    count:
      q: "¿Cuántos invitados estan esperando?"
      a: "Alrededor de 250 en un espacio de capacidad para 700 invitados sentados, con buena ventilación, protección para la lluvia, y jardines espaciosos."
    dress:
      q: "¿Cuál es el código de vestimenta?"
      a: "Formal, cómodo y colorido! Recomendamos vestidos no tan largos y tacones que no sean de aguja. Aunque la pista y las mesas están sobre suelo firme, les recomandamos llevar zapatos comodos para poder disfrutar los jardines. (Para cuidar el ambiente, evitaremos generar materiales innecesarios. Por lo tanto, no daremos pantuflas.)"

es_sm:
  dear: 'Querido'
  welcome: "Estamos muy felices y emocionados de invitarte a formar parte de la celebración de nuestra unión. Has sido importante en nuestro camino y no imaginamos celebrando este día sin tí. Te esperamos con los brazos abiertos."
  cantAttend: "Entendemos que puede ser complicado viajar en estos momentos. En caso de que no nos puedas acompañar presencialmente te invitaremos a la transmisión por zoom de la ceremonia civil donde al final podremos hacer un brindis virtual."
es_sf:
  dear: 'Querida'
  welcome: "Estamos muy felices y emocionados de invitarte a formar parte de la celebración de nuestra unión. Has sido importante en nuestro camino y no imaginamos celebrando este día sin tí. Te esperamos con los brazos abiertos."

es_pf:
  dear: 'Queridas'
  welcome: "Estamos muy felices y emocionados de invitarlas a formar parte de la celebración de nuestra unión. Ustedes han sido importantes para nuestro camino y no imaginamos celebrando este día sin ustedes. Las esperamos con los brazos abiertos."
  cantAttend: "Entendemos que puede ser complicado viajar en estos momentos. En caso de que no nos puedan acompañar presencialmente las invitaremos a la transmisión por zoom de la ceremonia civil donde al final podremos hacer un brindis virtual."

es_fam:
  dear: 'Querida'

es_fam_fancy:
  welcome: "Deseamos de todo corazón poder contar con la presencia de cada uno de ustedes para juntos celerar nuestra unión que se efectuará el dos de abril del dos mil veintidós a las dos de la tarde en la Parroquia de Nuestra Señora de Fátima en la misa oficiada por el Pbro. Santiago Pérez Santana.\nPosteriormente tendremos el agrado de recibirlos en el Jardín Princess Wedding Gardens en Santiago, Nuevo León para la ceremonia civil y la recepción.\nLlenos de sueños e ilusiones, los esperamos."

es_sf_fancy:
  welcome: "Deseamos de todo corazón poder contar con tu presencia para juntos celerar nuestra unión que se efectuará el dos de abril del dos mil veintidós a las dos de la tarde en la Parroquia de Nuestra Señora de Fátima en la misa oficiada por el Pbro. Santiago Pérez Santana.\nPosteriormente tendremos el agrado de recibirlos en Princess Wedding Gardens en Santiago, Nuevo León para la ceremonia civil y la recepción.\nLlenos de sueños e ilusiones, te esperamos."

es_pl_fancy:
  dear: 'Queridos'

en:
  date: 'April 2, 2022'
  invalid: 'This invitation link is not valid, please use the provided link.'
  seeMore: 'To see the details, please use the provided link.'
  dear: 'Dear'
  people: 'person|people'
  religious: 'Religious Ceremony'
  civil: 'Civil Ceremony'
  days: 'day|days'
  hours: 'hour|hours'
  minutes: 'minute|minutes'
  party: 'Reception'
  welcome: "We are very happy and excited to invite you to join us in celebrating our union. You have been important to our journey and we cannot imagine celebrating this day without you. We look forward to seeing you again."
  cantAttend: "We understand that it may be difficult to travel at this time. In case you cannot accompany us in person we invite you to the zoom transmission of the civil ceremony (in Spanish) where at the end we can make a virtual toast."
  qa:
    covid:
      q: "What are the COVID19 prevention measures?"
      bullets:
        - "Wear your maks correctly. If the guests wish, they can remove the mask while sitting at their table. Feel free to ask someone else to put their mask on. Please wear a mask to move close to people. "
        - "If you have symptoms close to the wedding date, please take the necessary measures and let us know so we can invite you to the broadcast of the civil ceremony. We want to meet again long after the wedding day."
        - "There will be antibacterial gel available at the tables."
        - "We ask you to wear your mask on the dance floor."
    count:
      q: "How many guests are we expecting?"
      a: "Around 250 guests, in a space with a capacity for 700 seated guests, with good ventilation, protection from the rain, and spacious gardens."
    dress:
      q: "What is the dress code?"
      a: "Formal, comfortable and colorful! We recommend not-so-long dresses and non-stiletto heels. Although the dance floor and the tables are on firm ground, we recommend that you wear comfortable shoes to enjoy the gardens. (To protect the environment, we will avoid generating unnecessary materials. Therefore, we will not give away slippers as it is common in other weddings.)"

</i18n>

<template>
  <div class="header cursive">
    <h1 class="names">Ana & Jorge</h1>
    <div class="setting">
      <div>{{ t("date") }}</div>
      <div>Monterrey, México</div>
    </div>
  </div>
  <picture>
    <source :srcset="imageSrc" />
    <img class="header-image" src="/header.jpg" />
  </picture>
  <vue-countdown
    class="countdown"
    :time="theDate"
    v-slot="{ days, hours, minutes }"
  >
    {{ days }} {{ t("days", days) }}, {{ hours }} {{ t("hours", hours) }},
    {{ minutes }} {{ t("minutes", minutes) }}
  </vue-countdown>
  <div v-if="invite && valid">
    <div class="invitee cursive">{{ t("dear") }} {{ names }}</div>
    <p class="welcome">
      {{ t("welcome") }}
    </p>
    <p v-if="outlander">{{ t("cantAttend") }}</p>
    <div class="events">
      <div v-if="religious" class="event religious">
        <div class="event-inner">
          <h2>{{ t("religious") }}</h2>
          <a
            class="location"
            href="https://goo.gl/maps/rT2VvQwDRsVV5Dk5A"
            target="_blank"
          >
            <font-awesome-icon icon="map-marked-alt" />
            Parroquia de Nuestra Señora de Fátima
          </a>
          <a class="time" href="/religious-X39tyLosabNWfxDJ.ics">
            <font-awesome-icon icon="clock" />
            2:00pm
          </a>
        </div>
      </div>
      <div v-if="civil" class="event civil">
        <div class="event-inner">
          <h2>{{ t("civil") }}</h2>
          <a
            class="location"
            href="https://goo.gl/maps/oDEdtD8oT2kykpkd6"
            target="_blank"
          >
            <font-awesome-icon icon="map-marked-alt" />
            Princess Wedding Gardens
          </a>
          <a class="time" href="/civil-TuKRXLXzRxLJLk2J.ics">
            <font-awesome-icon icon="clock" />
            5:00pm
          </a>
        </div>
      </div>
      <div v-if="party" class="event party">
        <div class="event-inner">
          <h2>{{ t("party") }}</h2>
          <a
            class="location"
            href="https://goo.gl/maps/oDEdtD8oT2kykpkd6"
            target="_blank"
          >
            <font-awesome-icon icon="map-marked-alt" />
            Princess Wedding Gardens
          </a>
          <a class="time" href="/party-qWybL3zibYHxnoNJ.ics">
            <font-awesome-icon icon="clock" />
            5:15pm - 11:30pm
          </a>
        </div>
      </div>
    </div>
    <div class="icons">
      <div>{{ count }} {{ t("people", count) }}</div>
      <inline-svg src="facemask.svg" />
      <inline-svg src="kids.svg" />
      <inline-svg src="formal.svg" />
      <inline-svg src="registry.svg" />
    </div>
    <div class="qa">
      <vue-collapsible-panel-group>
        <vue-collapsible-panel :expanded="false">
          <template #title>
            {{ t(`qa.covid.q`) }}
          </template>
          <template #content>
            <ul>
              <li v-for="index in 3" :key="index">
                {{ t(`qa.covid.bullets[${index}]`) }}
              </li>
            </ul>
          </template>
        </vue-collapsible-panel>
        <vue-collapsible-panel
          v-for="key in qaSimple"
          :key="key"
          :expanded="false"
        >
          <template #title>
            {{ t(`qa.${key}.q`) }}
          </template>
          <template #content>
            {{ t(`qa.${key}.a`) }}
          </template>
        </vue-collapsible-panel>
      </vue-collapsible-panel-group>
    </div>
  </div>
  <div v-if="invite && !valid" class="invalid">{{ t("invalid") }}</div>
  <div v-if="!invite" class="see-more">{{ t("seeMore") }}</div>
</template>

<script>
import { useI18n } from "vue-i18n";
import VueCountdown from "@chenfengyuan/vue-countdown";
import InlineSvg from "vue-inline-svg";

import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from "@dafcoe/vue-collapsible-panel";
import "@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css";

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

const TEMPLATES = {
  0: "es",
  1: "es_sm",
  2: "es_sf",
  3: "en",
  4: "es_pf",
  5: "es_fam",
  6: "es_fam_fancy",
  7: "es_sf_fancy",
  8: "es_pl_fancy",
};

export default {
  name: "App",
  components: {
    InlineSvg,
    VueCountdown,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },
  setup() {
    const { t, locale } = useI18n();

    return {
      t,
      locale,
    };
  },
  inject: ["$egg"],
  data() {
    return {
      HASHES: [],
      theDate: null,
      invite: false,
      valid: false,
      names: null,
      templateId: null,
      count: null,
      religious: false,
      civil: false,
      party: false,
      virtual: false,
      outlander: true,
      qaSimple: ["count", "dress"],
    };
  },
  computed: {
    imageSrc: function () {
      return this.$egg.easter ? "egg.svg" : "header.webp";
    },
  },
  async created() {
    this.HASHES = (
      await fetch("hashes.txt").then((response) => response.text())
    ).split("\n");
    const info = window.location.hash.substring(1);
    this.invite = info.length > 0;
    const encoder = new TextEncoder();
    const data = encoder.encode(info);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    this.valid = this.HASHES.indexOf(hash) > -1;

    const decoded = b64DecodeUnicode(info);
    let packed;
    [this.names, this.templateId, this.count, packed] = decoded.split("|");
    this.count = parseInt(this.count);

    if (this.valid) {
      [this.religious, this.civil, this.party, this.virtual, this.outlander] =
        parseInt(packed, 36)
          .toString(2)
          .padStart(5, "0")
          .split("")
          .map((b) => b === "1");

      //TODO modify countdown per event invite
    }

    if (this.religious) {
      this.theDate = new Date("April 2, 2022 14:00:00 GMT-06:00") - Date.now();
    } else if (this.civil) {
      this.theDate = new Date("April 2, 2022 17:00:00 GMT-06:00") - Date.now();
    } else {
      this.theDate = new Date("April 2, 2022 17:15:00 GMT-06:00") - Date.now();
    }

    this.locale = TEMPLATES[this.templateId];
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Ovo&family=Quicksand:wght@500&display=swap");

body {
  background-image: url(/bg-zakuras.webp);
  background-size: cover;
  background-attachment: fixed;
}

.cursive {
  font-family: "Ovo", cursive;
}

.header .names {
  margin: 0.5em;
}

.header .setting {
  font-size: 1.2em;
  display: flex;
  justify-content: space-around;
}

.header-image {
  max-width: 100%;
  margin: 1em 0;
  border-radius: 10px;
  box-shadow: 5px 5px 17px 2px rgba(0, 0, 0, 0.2);
}

.invalid {
  background: red;
  color: white;
  margin: 1em 0;
  padding: 1em;
}

.location,
.time {
  color: inherit;
  text-decoration-thickness: from-font;
  display: block;
}

.welcome {
  white-space: pre-line;
}

.see-more {
  font-weight: bold;
  margin: 1em 0;
  padding: 1em;
}

.invitee {
  color: var(--primary);
  padding: 0.8em;
  font-size: 1.5em;
}
.events {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.events .event {
  flex-basis: 100%;
  max-width: 230px;
  background: var(--background-accent);
  margin: 0.5em;
  padding: 2em 1em;
  border-radius: 10px;
  box-shadow: 5px 5px 17px 2px rgba(0, 0, 0, 0.2);
  display: table;
}

.event-inner {
  display: table-cell;
  vertical-align: middle;
}

.events h2 {
  margin-top: 0;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
  align-items: center;
}
.icons svg {
  fill: var(--primary);
  max-height: 75px;
  max-width: 75px;
}

.qa {
  margin-top: 10px;
}

.vcpg {
  border-radius: 10px !important;
}

.vcp__header {
  height: auto !important;
  background: var(--background) !important;
}

.vcp__header-title {
  display: initial !important;
}

.vcp__body-content {
  text-align: justify;
}

#app {
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--primary);
  max-width: 900px;
  margin: 0 auto;
  padding: 2em;
  background: var(--background);
  box-shadow: 0 0 5px 10px var(--background);
  --primary: #4e603c;
  --accent: red;
  --background: #fffafacf;
  --background-accent: #cbdff7;
}
</style>

export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 de Abril 2022"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liga de esta invitación no es válida, favor de usar la liga correcta."])},
        "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para ver los detalles del evento, favor de usar la liga que te enviaron los novios."])},
        "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queridos"])},
        "people": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["persona"]), _normalize(["personas"])])},
        "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["día"]), _normalize(["días"])])},
        "hours": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["hora"]), _normalize(["horas"])])},
        "minutes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["minuto"]), _normalize(["minutos"])])},
        "religious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misa"])},
        "civil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civil"])},
        "party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recepción"])},
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos muy felices y emocionados de invitarlos a formar parte de la celebración de nuestra unión. Ustedes han sido importantes en nuestro camino y no imaginamos celebrando este día sin ustedes. Los esperamos con los brazos abiertos."])},
        "cantAttend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendemos que puede ser complicado viajar en estos momentos. En caso de que no nos puedan acompañar presencialmente los invitaremos a la transmisión por zoom de la ceremonia civil donde al final podremos hacer un brindis virtual."])},
        "qa": {
          "covid": {
            "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuáles son las medidas de prevención COVID?"])},
            "bullets": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les pedimos el uso correcto del cubrebocas. Si los invitados lo desean pueden quitarse el cubrebocas al estar sentados en su mesa. Siéntase con libertad de pedirle a alguien mas que se coloque el cubrebocas. Favor de usar cubrebocas para desplazarse en el jardín cuando estén cerca de más personas."])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si cerca de la fecha de la boda presentan síntomas, por favor tomen las medidas apropiadas y avísennos para poder invitarlos a la transmisón de la ceremonia civil. Queremos acompañarnos no solo hoy, sino durante mucho tiempo."])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habrá gel antibacterial disponible en todas las mesas."])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les pedimos usar su cubrebocas en la pista de baile."])}
            ]
          },
          "count": {
            "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuántos invitados estan esperando?"])},
            "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alrededor de 250 en un espacio de capacidad para 700 invitados sentados, con buena ventilación, protección para la lluvia, y jardines espaciosos."])}
          },
          "dress": {
            "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuál es el código de vestimenta?"])},
            "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formal, cómodo y colorido! Recomendamos vestidos no tan largos y tacones que no sean de aguja. Aunque la pista y las mesas están sobre suelo firme, les recomandamos llevar zapatos comodos para poder disfrutar los jardines. (Para cuidar el ambiente, evitaremos generar materiales innecesarios. Por lo tanto, no daremos pantuflas.)"])}
          }
        }
      },
      "es_sm": {
        "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Querido"])},
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos muy felices y emocionados de invitarte a formar parte de la celebración de nuestra unión. Has sido importante en nuestro camino y no imaginamos celebrando este día sin tí. Te esperamos con los brazos abiertos."])},
        "cantAttend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendemos que puede ser complicado viajar en estos momentos. En caso de que no nos puedas acompañar presencialmente te invitaremos a la transmisión por zoom de la ceremonia civil donde al final podremos hacer un brindis virtual."])}
      },
      "es_sf": {
        "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Querida"])},
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos muy felices y emocionados de invitarte a formar parte de la celebración de nuestra unión. Has sido importante en nuestro camino y no imaginamos celebrando este día sin tí. Te esperamos con los brazos abiertos."])}
      },
      "es_pf": {
        "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queridas"])},
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos muy felices y emocionados de invitarlas a formar parte de la celebración de nuestra unión. Ustedes han sido importantes para nuestro camino y no imaginamos celebrando este día sin ustedes. Las esperamos con los brazos abiertos."])},
        "cantAttend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendemos que puede ser complicado viajar en estos momentos. En caso de que no nos puedan acompañar presencialmente las invitaremos a la transmisión por zoom de la ceremonia civil donde al final podremos hacer un brindis virtual."])}
      },
      "es_fam": {
        "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Querida"])}
      },
      "es_fam_fancy": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseamos de todo corazón poder contar con la presencia de cada uno de ustedes para juntos celerar nuestra unión que se efectuará el dos de abril del dos mil veintidós a las dos de la tarde en la Parroquia de Nuestra Señora de Fátima en la misa oficiada por el Pbro. Santiago Pérez Santana.\nPosteriormente tendremos el agrado de recibirlos en el Jardín Princess Wedding Gardens en Santiago, Nuevo León para la ceremonia civil y la recepción.\nLlenos de sueños e ilusiones, los esperamos."])}
      },
      "es_sf_fancy": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseamos de todo corazón poder contar con tu presencia para juntos celerar nuestra unión que se efectuará el dos de abril del dos mil veintidós a las dos de la tarde en la Parroquia de Nuestra Señora de Fátima en la misa oficiada por el Pbro. Santiago Pérez Santana.\nPosteriormente tendremos el agrado de recibirlos en Princess Wedding Gardens en Santiago, Nuevo León para la ceremonia civil y la recepción.\nLlenos de sueños e ilusiones, te esperamos."])}
      },
      "es_pl_fancy": {
        "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queridos"])}
      },
      "en": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2, 2022"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This invitation link is not valid, please use the provided link."])},
        "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To see the details, please use the provided link."])},
        "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear"])},
        "people": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["person"]), _normalize(["people"])])},
        "religious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religious Ceremony"])},
        "civil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civil Ceremony"])},
        "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["day"]), _normalize(["days"])])},
        "hours": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["hour"]), _normalize(["hours"])])},
        "minutes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["minute"]), _normalize(["minutes"])])},
        "party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reception"])},
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are very happy and excited to invite you to join us in celebrating our union. You have been important to our journey and we cannot imagine celebrating this day without you. We look forward to seeing you again."])},
        "cantAttend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We understand that it may be difficult to travel at this time. In case you cannot accompany us in person we invite you to the zoom transmission of the civil ceremony (in Spanish) where at the end we can make a virtual toast."])},
        "qa": {
          "covid": {
            "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are the COVID19 prevention measures?"])},
            "bullets": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wear your maks correctly. If the guests wish, they can remove the mask while sitting at their table. Feel free to ask someone else to put their mask on. Please wear a mask to move close to people. "])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have symptoms close to the wedding date, please take the necessary measures and let us know so we can invite you to the broadcast of the civil ceremony. We want to meet again long after the wedding day."])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There will be antibacterial gel available at the tables."])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We ask you to wear your mask on the dance floor."])}
            ]
          },
          "count": {
            "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many guests are we expecting?"])},
            "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Around 250 guests, in a space with a capacity for 700 seated guests, with good ventilation, protection from the rain, and spacious gardens."])}
          },
          "dress": {
            "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the dress code?"])},
            "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formal, comfortable and colorful! We recommend not-so-long dresses and non-stiletto heels. Although the dance floor and the tables are on firm ground, we recommend that you wear comfortable shoes to enjoy the gardens. (To protect the environment, we will avoid generating unnecessary materials. Therefore, we will not give away slippers as it is common in other weddings.)"])}
          }
        }
      }
    }
  })
}

import { createApp, reactive } from "vue";
import App from "./App.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faMapMarkedAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import KonamiCode from "vue3-konami-code";

import i18n from "./i18n";

library.add(faMapMarkedAlt, faClock);

const app = createApp(App)
  .use(i18n)
  .component("font-awesome-icon", FontAwesomeIcon);

const egg = reactive({ easter: false });

app.provide("$egg", egg);

app.use(KonamiCode, {
  onKonamiCodeEntered: function () {
    egg.easter = !egg.easter;
  },
});

app.mount("#app");

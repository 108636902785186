import { createI18n } from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

export default createI18n({
  legacy: false,
  // globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: {
    es_sf: ["es_sm"],
    es_sm: ["es"],
    es_pf: ["es"],
    es_fam: ["es"],
    es_fam_fancy: ["es_fam"],
    es_sf_fancy: ["es_sf"],
    es_pl_fancy: ["es_fam_fancy"],
    default: ["es"],
  },
});
